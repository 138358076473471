import type { Organism } from '@verndale/core';
import globModules from './glob-modules';
import modulesReact from './modules-react';

const modules: Organism[] = [
  {
    name: 'accordion',
    loader: () => import('./modules/accordion')
  },
  {
    name: 'search-header',
    loader: () => import('./modules/search-header')
  },
];

export default [...globModules, ...modules, ...modulesReact];
