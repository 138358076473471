import type { Organism } from '@verndale/core';
import React from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const reactModule = (Component: any, nodeList: NodeListOf<HTMLElement>) => {
  return nodeList.forEach(node =>
    createRoot(node).render(<Component {...node.dataset} />)
  );
};

const modulesReact: Organism[] = [
  {
    name: 'mockApi',
    styles: () => import('../scss/modules/mock-api.scss'),
    loader: () => import('./react-components/mockApi'),
    render: reactModule
  },
  {
    name: 'newSearchResults',
    loader: () =>
      import('./react-components/newSearchResults/index'),
    render: reactModule
  },
];

export default [...modulesReact];
